export default function LandingTitleName() {
    return (
        <>
            <h1>
                Jonathan E<br />
                Cowperthwait
            </h1>
        </>
    );
}
